import _ from 'lodash';

import {
  COOKIE_MAP,
  getCookie,
  removeCookie,
  setCookie
} from 'services/cookie.service';

const isLocalStorageAvailable = () => {
  try {
    return (
      typeof window !== 'undefined' && typeof localStorage !== 'undefined'
    );
  } catch (e) {
    return false;
  }
};

// TODO: alter the function names below to indicate that data contain identity and auth token
export const getAuth = () => {
  let auth = getCookie({ name: COOKIE_MAP.PARTNER_PORTAL_USER_IDENTITY });
  if (auth?.token) {
    return auth;
  }

  if (isLocalStorageAvailable()) {
    auth = localStorage.getItem(COOKIE_MAP.PARTNER_PORTAL_USER_IDENTITY);
    if (auth) {
      return setCookie({
        name: COOKIE_MAP.PARTNER_PORTAL_USER_IDENTITY,
        data: auth
      });
    }
  }
  return null;
};

export const setAuth = (authObject) => {
  if (isLocalStorageAvailable()) {
    localStorage.setItem(
      COOKIE_MAP.PARTNER_PORTAL_USER_IDENTITY,
      JSON.stringify(authObject)
    );
  }
  return setCookie({
    name: COOKIE_MAP.PARTNER_PORTAL_USER_IDENTITY,
    data: authObject
  });
};

export const removeAuth = () => {
  if (isLocalStorageAvailable()) {
    localStorage.removeItem(COOKIE_MAP.PARTNER_PORTAL_USER_IDENTITY);
  }
  removeCookie({ name: COOKIE_MAP.PARTNER_PORTAL_USER_IDENTITY });
};

export const getAuthToken = () => getAuth()?.token || null;

export const isAuthenticated = () => {
  const token = getAuthToken();
  return Boolean(!_.isEmpty(token));
};
