import {
  CART_ORDER_SOURCE,
  IMAGEKIT_DAM_ROOT_DIRECTORY,
  MEDIA_SOURCE,
  mediaStoreBaseURL,
  NO_PRODUCT_IMAGE,
  STATIC_MEDIA_STORE_PATH_STUB,
  staticMediaStoreBaseURL
} from '@/config/common';

const imageKitMediaURL = process.env.NEXT_PUBLIC_MEDIA_STORE_IMAGE_KIT_ORIGIN
  ? `${process.env.NEXT_PUBLIC_MEDIA_STORE_IMAGE_KIT_ORIGIN}${IMAGEKIT_DAM_ROOT_DIRECTORY}`
  : STATIC_MEDIA_STORE_PATH_STUB;

const localHostMediaURL = '/';

const removeLeadingBackSlash = (str = '') => str.replace(/^\//, '');

const sortArrayOfObjectByKey = (array, key) =>
  array.sort((a, b) => (a[`${key}`] > b[`${key}`] ? 1 : -1));

export const getRankedImages = (images) => {
  const hasRank = images.length === images.every(({ rank }) => rank);
  if (hasRank) {
    return sortArrayOfObjectByKey(images, 'rank');
  }

  const hasSortOrder =
    images.length === images.every(({ sortOrder }) => sortOrder);
  if (hasSortOrder) {
    sortArrayOfObjectByKey(images, 'sortOrder').map((image, index) => ({
      ...image,
      rank: index + 1
    }));
  }

  return images.map((image, index) => ({
    ...image,
    source: image.source || MEDIA_SOURCE.AWS_S3,
    rank: index + 1
  }));
};

const patchOriginForStorybook = (origin) =>
  origin || STATIC_MEDIA_STORE_PATH_STUB;

export const getImageURL = ({ source, url: urlPath }) => {
  let origin = '';
  switch (source) {
    case MEDIA_SOURCE.CODE_REPO:
      origin = staticMediaStoreBaseURL;
      break;
    case MEDIA_SOURCE.IMAGE_KIT:
      origin = imageKitMediaURL;
      break;
    case MEDIA_SOURCE.LOCALHOST:
      origin = localHostMediaURL;
      break;
    case MEDIA_SOURCE.AWS_S3:
    default:
      origin = mediaStoreBaseURL;
      break;
  }
  origin = patchOriginForStorybook(origin);
  return urlPath ? `${origin}/${removeLeadingBackSlash(urlPath)}` : '';
};

export const mediaListMapperForBidItemDetailAPI = ({
  tenderItem: {
    cartItemMediaList,
    productMediaList,
    showProductMedia,
    ...restTenderItem
  },
  ...restBidItem
}) => {
  const transformMediaListCommon = (items) =>
    items
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((image, index) => ({
        ...image,
        sortOrder: index + 1
      }));

  const cartItemMediaListForCarousel = transformMediaListCommon(
    cartItemMediaList || []
  );

  const baseSortOrderForProductMedia = cartItemMediaListForCarousel.length;
  const productMedia = productMediaList || [];

  const productMediaForCarousel = transformMediaListCommon(
    showProductMedia ? productMedia : []
  ).map(({ sortOrder, ...image }) => ({
    ...image,
    sortOrder: sortOrder + baseSortOrderForProductMedia
  }));

  const itemMediaForCarousel = [
    ...cartItemMediaListForCarousel,
    ...productMediaForCarousel
  ];
  if (itemMediaForCarousel.length === 0) {
    itemMediaForCarousel.push(NO_PRODUCT_IMAGE);
  }
  return {
    ...restBidItem,
    tenderItem: {
      ...restTenderItem,
      mediaList: itemMediaForCarousel
    }
  };
};

export const itemMediaListMapper = ({
  additionalMediaList: additionalMediaListRaw,
  showProductMedia,
  source = CART_ORDER_SOURCE.CART,
  ...item
}) => {
  const {
    product: { productMedia }
  } = item;

  const transformMediaListCommon = (items) =>
    items
      .map(({ id: itemMediaId, media, sortOrder }) => ({
        ...media,
        [`${
          source === CART_ORDER_SOURCE.CART
            ? 'cartItemMediaId'
            : 'orderItemMediaId'
        }`]: itemMediaId,
        sortOrder
      }))
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((image, index) => ({
        ...image,
        sortOrder: index + 1
      }));

  const additionalMediaListForCarousel = transformMediaListCommon(
    additionalMediaListRaw || []
  ).map((image) => ({
    ...image,
    entityType: source === CART_ORDER_SOURCE.CART ? 'cartItem' : 'orderItem'
  }));

  const baseSortOrderToAccommodateHoles =
    additionalMediaListForCarousel.length;
  const productMediaList = productMedia || [];

  const productMediaForCarousel = transformMediaListCommon(
    showProductMedia ? productMediaList : []
  ).map(({ sortOrder, ...image }) => ({
    ...image,
    entityType: 'product',
    sortOrder: sortOrder + baseSortOrderToAccommodateHoles
  }));

  const itemMediaForCarousel = [
    ...additionalMediaListForCarousel,
    ...productMediaForCarousel
  ];

  if (itemMediaForCarousel.length === 0) {
    itemMediaForCarousel.push(NO_PRODUCT_IMAGE);
  }

  return {
    ...item,
    itemMediaForCarousel
  };
};
