import { Fragment } from 'react';

import { OrderDetailSectionTitle, Text } from '@/components/atomic/atoms';
import { ORDER_STATUS } from '@/config/common';
import { parseFormatPriceValueFromAPI } from '@/lib/numberStringUtils';

const getStatusBasedClass = ({ status }) => {
  let dictionary;
  switch (status) {
    case ORDER_STATUS.CANCELLED.value:
      dictionary = {
        grandTotalClass: 'bg-dim-gray'
      };
      break;
    default:
      dictionary = {
        grandTotalClass: 'bg-olive-black'
      };
      break;
  }
  return dictionary;
};

const OrderSummary = ({
  orderSummary: {
    orderItemAmount,
    partnerDeliveryCharge,
    partnerOrderTotal,
    preVAT,
    VAT
  },
  status
}) => {
  const { grandTotalClass } = getStatusBasedClass({ status });

  const OrderSummaryLabelValueList = [
    {
      label: 'Item Total',
      show: Boolean(orderItemAmount),
      subText: '(AED)',
      value: parseFormatPriceValueFromAPI(orderItemAmount)
    },
    {
      label: 'Delivery Charge',
      show: Boolean(partnerDeliveryCharge),
      subText: '(AED)',
      value: parseFormatPriceValueFromAPI(partnerDeliveryCharge)
    },
    {
      label: 'Pre VAT Total',
      show: Boolean(partnerDeliveryCharge),
      subText: '(AED)',
      value: parseFormatPriceValueFromAPI(preVAT)
    },
    {
      label: 'VAT',
      show: Boolean(VAT),
      subText: '(5%)',
      value: parseFormatPriceValueFromAPI(VAT)
    },
    {
      className: 'font-semibold',
      highlightRowClassNames: `${grandTotalClass} text-white rounded-b-lg`,
      label: 'Grand Total',
      show: true,
      subText: '(AED)',
      value: parseFormatPriceValueFromAPI(partnerOrderTotal)
    }
  ];

  return (
    <Fragment>
      <OrderDetailSectionTitle {...{ title: 'Summary', status }} />
      <div className='flex flex-col mx-4 md:mx-0 shadow border border-neutral mb-5 rounded-lg text-nero'>
        {OrderSummaryLabelValueList.filter(({ show }) => show).map(
          (
            { className = '', highlightRowClassNames, label, subText, value },
            index
          ) => (
            <div
              key={index}
              className={[
                'flex py-2 px-4 md:px-8 justify-between',
                highlightRowClassNames ?? ''
              ].join(' ')}
            >
              <div className='flex gap-1'>
                <Text
                  {...{
                    className: `text-sm md:text-base font-medium ${className}`,
                    content: label
                  }}
                />
                <Text
                  {...{
                    className: 'text-xs md:text-sm flex self-center',
                    content: subText
                  }}
                />
              </div>
              <Text
                {...{
                  className: `text-sm md:text-base font-medium  ${className}`,
                  content: value
                }}
              />
            </div>
          )
        )}
      </div>
    </Fragment>
  );
};

export default OrderSummary;
