import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';

import {
  IconWithLabel,
  OrderDetailSectionTitle,
  Text
} from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import { copyToClipBoard } from '@/lib/utils';

const EventLocation = ({ exactAddress, googleLocationPin }) => (
  <div className='flex gap-2'>
    <div className='w-6 self-center'>
      <Image
        alt='location'
        height={0}
        src={`${staticMediaStoreBaseURL}/icons/location-icon-with-frame.svg`}
        style={{ width: 24, height: 24 }}
        width={0}
      />
    </div>
    <Link
      className='flex flex-1'
      href={googleLocationPin}
      target='_blank'
    >
      <Text
        {...{
          className: 'text-sm md:text-base text-denim-blue font-medium',
          content: exactAddress
        }}
      />
    </Link>
  </div>
);

const HostName = ({ eventContactName }) => (
  <IconWithLabel
    {...{
      containerClass: 'flex gap-2',
      imgAlt: 'cancelled order',
      imageContainerClass: 'w-6 self-center',
      imgHeight: 24,
      imgSrc: 'user-red-with-frame.svg',
      imgWidth: 24,
      value: eventContactName,
      valueStyle: ''
    }}
  />
);

const HostPhoneNumber = ({ mobile }) => (
  <div className='flex gap-2'>
    <div className='w-6 self-center'>
      <Image
        alt='phone-icon'
        height={0}
        src={`${staticMediaStoreBaseURL}/icons/phone-icon-with-frame.svg`}
        style={{ width: 24, height: 24 }}
        width={0}
      />
    </div>
    <Link href={`tel:${mobile}`}>
      <Text {...{ content: mobile }} />
    </Link>
    <div className='w-5 self-top'>
      <Image
        alt='copy phone'
        height={0}
        onClick={() => copyToClipBoard(mobile)}
        src={`${staticMediaStoreBaseURL}/icons/copy-icon.svg`}
        style={{ width: 24, height: 24 }}
        width={0}
      />
    </div>
  </div>
);

const OrderDeliveryDetails = ({
  eventContactName,
  exactAddress,
  googleLocationPin,
  mobile,
  status
}) => (
  <Fragment>
    <OrderDetailSectionTitle {...{ title: 'Delivery Details', status }} />
    <div className='flex flex-col gap-3 md:gap-4 mx-4 md:mx-0 p-4 md:p-8 shadow border border-neutral mb-5 rounded-lg text-nero'>
      <div className='flex flex-col md:flex-row gap-3 md:gap-8 text-sm md:text-base font-medium text-nero'>
        <HostName {...{ eventContactName }} />
        <HostPhoneNumber {...{ mobile }} />
      </div>
      <EventLocation {...{ exactAddress, googleLocationPin }} />
    </div>
  </Fragment>
);

export default OrderDeliveryDetails;
