import Image from 'next/image';
import Link from 'next/link';

import { Text } from '@/components/atomic/atoms';
import { DEVICE_SURFACE, staticMediaStoreBaseURL } from '@/config/common';
import { GetUiDeviceSurface } from '@/lib/screenResolution';
import { PAGE_NAME, getPageURL } from '@/services/partnerPortal.service';

const socialMediaIconList = [
  {
    url: 'https://www.linkedin.com/company/hafla-official',
    icon: 'linked-in.svg',
    alt: 'linked-in'
  },
  {
    url: 'https://www.youtube.com/channel/UCaUo41TfdsE8Lr_OCb-modg',
    icon: 'youtube.svg',
    alt: 'youtube'
  },
  {
    url: 'https://www.facebook.com/hafla.Official',
    icon: 'facebook.svg',
    alt: 'facebook'
  },
  {
    url: 'https://www.instagram.com/hafla.official',
    icon: 'instagram.svg',
    alt: 'instagram'
  },
  {
    url: 'http://www.tiktok.com/@haflaofficial',
    icon: 'tiktok.svg',
    alt: 'tiktok'
  }
];

const companySectionDataWeb = [
  {
    href: getPageURL({ pageName: PAGE_NAME.HOME.label }),
    label: 'Home'
  },
  {
    href: getPageURL({ pageName: PAGE_NAME.CONTACT_US.label }),
    label: 'Contact Us'
  }
];

const companySectionDataMobile = [
  {
    href: getPageURL({ pageName: PAGE_NAME.HOME.label }),
    label: 'Home'
  },
  {
    href: getPageURL({ pageName: PAGE_NAME.CONTACT_US.label }),
    label: 'Contact Us'
  },
  {
    href: getPageURL({ pageName: PAGE_NAME.TERMS_AND_CONDITIONS.label }),
    label: 'Terms & Conditions'
  }
];

const exploreSectionData = [
  {
    href: getPageURL({ pageName: PAGE_NAME.BID_LIST.label }),
    label: 'Quotes'
  },
  {
    href: getPageURL({ pageName: PAGE_NAME.ORDER_LIST.label }),
    label: 'Orders'
  },
  {
    href: getPageURL({ pageName: PAGE_NAME.ORDER_DELIVERY_AND_PICKUPS.label }),
    label: 'Deliveries & Pickups'
  },
  {
    href: getPageURL({ pageName: PAGE_NAME.PROFILE.label }),
    label: 'Profile'
  }
];

const policySectionData = [
  {
    href: getPageURL({ pageName: PAGE_NAME.TERMS_AND_CONDITIONS.label }),
    label: 'Terms & Conditions'
  }
];

const companySectionDataMap = {
  mobile: companySectionDataMobile,
  web: companySectionDataWeb
};

const Heading = ({ label }) => (
  <div>
    <Text
      {...{
        content: label,
        className:
          'text-xs font-medium md:text-lg uppercase text-white mb-3 md:mb-4'
      }}
    />

    <div className='w-11 h-0.5 bg-brand-gradient'></div>
  </div>
);

const LinkSection = ({ label, href }) => (
  <li>
    <Link href={href}>
      <Text
        {...{
          content: label,
          className: 'text-white text-xs md:text-base font-light py-1'
        }}
      />
    </Link>
  </li>
);

const FooterMenuSection = ({ className = 'w-2/4', data, headingLabel }) => (
  <div className={`${className} md:w-1/4 pt-4 md:pt-0`}>
    <Heading {...{ label: headingLabel }} />
    <ul className='pt-2 md:pt-4'>
      {data.map(({ label, href }, index) => (
        <LinkSection
          key={index}
          {...{ label, href }}
        />
      ))}
    </ul>
  </div>
);

const Footer = () => {
  const surface = GetUiDeviceSurface();
  const companySectionData = companySectionDataMap[surface];
  return (
    <footer>
      <div className='bg-nero p-5 md:px-20 md:pt-12 w-full footer-padding-bottom'>
        <div className='flex flex-wrap'>
          <div className='w-full md:w-1/4'>
            <Link href={getPageURL({ pageName: PAGE_NAME.HOME.label })}>
              <Image
                alt='Hafla'
                className='inline-block max-w-17.5 md:max-w-26'
                height={0}
                priority={true}
                src={`${staticMediaStoreBaseURL}/icons/hafla.svg`}
                style={{ width: 120, height: 'auto' }}
                width={0}
              />
            </Link>
            <Text
              {...{
                content: 'Partner Portal',
                className:
                  'w-full mt-2 md:mt-3 font-semibold text-sm md:text-xl text-white md:text-light-gray'
              }}
            />

            <div className='md:justify-start pt-10 pb-4 hidden md:block'>
              <ul className='list-none justify-start grid grid-flow-row grid-cols-5 gap-4 w-max'>
                {socialMediaIconList.map(({ url, icon, alt }, index) => (
                  <li key={index}>
                    <Link
                      href={url}
                      target='_blank'
                    >
                      <Image
                        alt={alt}
                        height={0}
                        src={`${staticMediaStoreBaseURL}/icons/${icon}`}
                        style={{ width: 30, height: 30 }}
                        width={0}
                      />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <FooterMenuSection
            {...{
              className: 'w-3/5',
              data: companySectionData,
              headingLabel: 'COMPANY'
            }}
          />
          <FooterMenuSection
            {...{
              className: 'w-2/5',
              data: exploreSectionData,
              headingLabel: 'EXPLORE'
            }}
          />
          {surface === DEVICE_SURFACE.WEB && (
            <FooterMenuSection
              {...{ headingLabel: 'POLICY', data: policySectionData }}
            />
          )}
        </div>
        <div className='justify-center flex md:justify-start pt-6 pb-4 md:hidden'>
          <ul className='list-none justify-start grid grid-flow-row grid-cols-5 gap-4 w-max'>
            {socialMediaIconList.map(({ url, icon, alt }, index) => (
              <li key={index}>
                <Link
                  href={url}
                  target='_blank'
                >
                  <Image
                    alt={alt}
                    height={0}
                    src={`${staticMediaStoreBaseURL}/icons/${icon}`}
                    style={{ width: 30, height: 30 }}
                    width={0}
                  />
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className='mt-4 md:mt-10'>
          <Text
            {...{
              content: 'Copyright 2024 Hafla. All rights reserved.',
              className:
                'text-xs md:text-sx font-medium text-center md:text-left text-dim-gray'
            }}
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
