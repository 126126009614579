import { useMemo, useState } from 'react';
import {
  ClearRefinements,
  Menu,
  RefinementList,
  useCurrentRefinements
} from 'react-instantsearch';

import { SortResultsModal } from '@/components/atomic/atoms';
import {
  DynamicFilterMobile,
  NumericFilterWithDatePicker
} from '@/components/atomic/molecules';
import { BID_LIST_INDEX } from '@/config/common';
import {
  DEFAULT_TAB_CATEGORY,
  SORT_BY_ITEMS_BID_LIST
} from '@/config/searchkit/bidList';
import { DEFAULT_FILTER_OPTIONS_LIMIT } from '@/config/searchkit/common';

import {
  getDateRangeItemsForBidList,
  transformDeliveryMode
} from '@/helpers/searchkit';
import {
  LOCAL_STORAGE_KEYS,
  removeLocalStorageValue
} from '@/services/localStorage.service';

const classNameGroup = {
  checkbox:
    'content-searchkit-checkbox searchkit-checkbox checked:content-searchkit-checkbox-checked',
  noResults: 'searchkit-no-result-found min-h-48',
  count: 'bg-brand text-white border-none font-medium text-xs',
  labelText:
    'text-sm text-dim-gray font-medium searchkit-label-text cursor-pointer',
  list: 'flex flex-col gap-2 overflow-x-hidden overflow-y-auto w-80 min-h-48 max-h-80',
  item: 'pr-2',
  searchBox: 'searchkit-search-box',
  selectedItem: 'searchkit-selected-item',
  showMore: 'searchkit-filter-show-more-button'
};

const getCountForAttribute = ({ attribute, currentRefinements }) => {
  const isRefinement = currentRefinements.find(
    (refinement) => refinement.attribute === attribute
  );
  return isRefinement?.refinements.length;
};

const SearchSortFilterBidsMobileOrganism = ({
  clearDateFilter,
  setClearDateFilter,
  setSelectedMenu
}) => {
  const [openFilter, setOpenFilter] = useState(null);

  const { items: currentRefinements } = useCurrentRefinements();

  const handleToggleFilter = (attribute) => {
    setOpenFilter((prevOpenFilter) =>
      prevOpenFilter === attribute ? null : attribute
    );
  };

  const transformDeliveryModeItemsMemoized = useMemo(
    transformDeliveryMode,
    []
  );

  const dynamicFilterList = [
    {
      attribute: 'eventDateInSeconds',
      buttonName: 'Event Date',
      Component: NumericFilterWithDatePicker,
      componentProps: {
        attribute: 'eventDateInSeconds',
        clearDateFilter,
        items: useMemo(() => getDateRangeItemsForBidList(), []),
        menuAttribute: 'bidListTabCategory',
        index: BID_LIST_INDEX
      },
      count: getCountForAttribute({
        attribute: 'eventDateInSeconds',
        currentRefinements
      }),
      icon: 'calendar-red.svg',
      isOpen: openFilter === 'eventDateInSeconds',
      onClearAllRefinement: () => {
        removeLocalStorageValue({
          keys: [
            LOCAL_STORAGE_KEYS.DATE_PICKER_VALUE,
            LOCAL_STORAGE_KEYS.DATE_RANGE
          ]
        });
        setClearDateFilter(!clearDateFilter);
        handleToggleFilter();
      },
      onToggle: handleToggleFilter,
      selectedIcon: 'event-date-icon.svg',
      setOpenFilter,
      showCount: false
    },
    {
      attribute: 'eventType',
      buttonName: 'Event Type',
      Component: RefinementList,
      componentProps: {
        attribute: 'eventType',
        classNames: {
          ...classNameGroup,
          root: 'searchkit-search-box-event-type'
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        searchable: true,
        searchablePlaceholder: 'Search',
        showMore: 'true'
      },
      count: getCountForAttribute({
        attribute: 'eventType',
        currentRefinements
      }),
      icon: 'event-type.svg',
      isOpen: openFilter === 'eventType',
      onClearAllRefinement: () => {
        handleToggleFilter();
      },
      onToggle: handleToggleFilter,
      selectedIcon: 'event-type-white.svg',
      setOpenFilter
    },
    {
      attribute: 'plannerName',
      buttonName: 'Planner Name',
      Component: RefinementList,
      componentProps: {
        attribute: 'plannerName',
        classNames: {
          ...classNameGroup,
          list: 'flex flex-col gap-2 mt-4 overflow-x-auto min-h-48 max-h-80',
          root: 'searchkit-search-box-event-type'
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        searchable: true,
        searchablePlaceholder: 'Search',
        showMore: true
      },
      count: getCountForAttribute({
        attribute: 'plannerName',
        currentRefinements
      }),
      icon: 'planner-red.svg',
      isOpen: openFilter === 'plannerName',
      onClearAllRefinement: () => {
        handleToggleFilter();
      },
      onToggle: handleToggleFilter,
      selectedIcon: 'planner-white.svg',
      setOpenFilter
    },
    {
      attribute: 'eventCity',
      buttonName: 'Event City',
      Component: RefinementList,
      componentProps: {
        attribute: 'eventCity',
        classNames: {
          ...classNameGroup,
          list: 'flex flex-col gap-4 overflow-x-auto max-h-80'
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        searchablePlaceholder: 'Search',
        showMore: true
      },
      count: getCountForAttribute({
        attribute: 'eventCity',
        currentRefinements
      }),
      icon: 'location-icon-with-frame.svg',
      isOpen: openFilter === 'eventCity',
      onClearAllRefinement: () => {
        handleToggleFilter();
      },
      onToggle: handleToggleFilter,
      selectedIcon: 'location-white.svg',
      setOpenFilter
    },
    {
      attribute: 'deliveryMode',
      buttonName: 'Delivery Mode',
      Component: Menu,
      componentProps: {
        attribute: 'deliveryMode',
        classNames: {
          ...classNameGroup,
          list: 'flex flex-col gap-2 overflow-x-auto max-h-80'
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        showMore: 'true',
        transformItems: transformDeliveryModeItemsMemoized
      },
      count: getCountForAttribute({
        attribute: 'deliveryMode',
        currentRefinements
      }),
      icon: 'searchkit-delivery-icon.svg',
      isOpen: openFilter === 'deliveryMode',
      onClearAllRefinement: () => {
        handleToggleFilter();
      },
      onToggle: handleToggleFilter,
      selectedIcon: 'searchkit-delivery-white-icon.svg',
      setOpenFilter
    }
  ];

  return (
    <div className='flex md:hidden mt-3'>
      <div className='pr-2'>
        <SortResultsModal
          {...{
            className: 'h-8',
            items: SORT_BY_ITEMS_BID_LIST
          }}
        />
      </div>
      <div className='flex border-l border-platinum gap-3 overflow-auto justify-start items-center pl-3'>
        {dynamicFilterList.map((filterProps, index) => (
          <DynamicFilterMobile
            key={index}
            {...{ filterProps }}
          />
        ))}
        <div
          onClick={() => {
            removeLocalStorageValue({
              keys: [
                LOCAL_STORAGE_KEYS.DATE_PICKER_VALUE,
                LOCAL_STORAGE_KEYS.DATE_RANGE
              ]
            });
            setClearDateFilter(!clearDateFilter);
            setSelectedMenu(DEFAULT_TAB_CATEGORY);
          }}
        >
          <ClearRefinements
            {...{
              classNames: {
                button: 'searchkit-clear-all-button-mobile text-nowrap',
                root: 'searchkit-clear-all-button-container'
              },
              translations: {
                resetButtonText: 'Clear All'
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchSortFilterBidsMobileOrganism;
