import { IconWithLabel } from '@/components/atomic/atoms';
import { MENU_ICON_MAP } from '@/config/common';

const ProfileMobile = ({ field, value }) =>
  MENU_ICON_MAP[field] &&
  value && (
    <div className='px-8'>
      <IconWithLabel
        {...{
          containerClass: 'flex gap-3',
          imgAlt: `${MENU_ICON_MAP[field].alt}`,
          imageContainerClass: 'flex self-center w-7',
          imgHeight: 28,
          imgSrc: MENU_ICON_MAP[field].icon,
          imgWidth: 28,
          value,
          valueStyle:
            'self-center text-base md:text-xl font-medium text-dim-gray break-all flex flex-1'
        }}
      />
    </div>
  );

export default ProfileMobile;
