import { CTA } from '@/components/atomic/atoms';
import { BID_STATUS } from '@/config/bid';

const BidCTA = ({
  disableSubmitCTA,
  handleSubmit,
  onSubmit,
  setShowRequestCallbackModal,
  status
}) => {
  const isBidExpired = status === BID_STATUS.EXPIRED.value;
  const CTAList = [
    {
      buttonColor: `${
        isBidExpired
          ? 'border-dim-gray text-dim-gray'
          : 'border-brand text-brand'
      } bg-white border h-11 px-2 md:py-4 text-sm font-medium w-full`,
      children: 'Request Callback',
      onClick: () => setShowRequestCallbackModal(true)
    },
    {
      buttonColor: `${
        isBidExpired ? 'bg-dim-gray' : 'bg-brand-gradient'
      } text-white h-11 px-6 md:py-4 text-sm w-full submit-cta`,
      children: 'Submit',
      className: `font-medium px-3 ${disableSubmitCTA ? 'opacity-50' : ' '}`,
      disabled: disableSubmitCTA,
      onClick: handleSubmit(onSubmit)
    }
  ];
  return (
    <div
      className={`flex gap-5 justify-center my-7 mx-6 md:mx-0 ${status} bid-cta`}
    >
      {CTAList.map((props, index) => (
        <div
          key={index}
          className='w-52 md:w-1/2'
        >
          <CTA {...props} />
        </div>
      ))}
    </div>
  );
};

export default BidCTA;
