import Image from 'next/image';

import {
  IconWithLabel,
  Text,
  TextKeyValuePair
} from '@/components/atomic/atoms';
import { TextWithIcon } from '@/components/atomic/nuclei';
import { ORDER_STATUS, staticMediaStoreBaseURL } from '@/config/common';
import { convertToStandardDateFormat } from '@/lib/dateAndTime';
import { parseNumberedLabel } from '@/lib/utils';

const getStatusBasedOptions = ({ status }) => {
  let dictionary;
  switch (status) {
    case ORDER_STATUS.CANCELLED.value:
      dictionary = {
        orderNumberClass: 'bg-dim-gray'
      };
      break;
    default:
      dictionary = {
        orderNumberClass: 'bg-planner-cart-bg'
      };
      break;
  }
  return dictionary;
};

const OrderEventInfoWeb = ({ eventInfo, exactAddress, status }) => {
  const { eventDate, eventName, orderNumber, plannerName } = eventInfo || {};

  const { orderNumberClass } = getStatusBasedOptions({ status });

  return (
    <div className='relative -mt-20 md:mt-6'>
      <div
        className={`${orderNumberClass} px-4 md:px-6 py-5 flex flex-col gap-3 rounded-2xl shadow-md mx-6 md:mx-0 z-50`}
      >
        <div className='flex justify-between'>
          <div className='flex flex-col'>
            <Text
              {...{
                content: eventName,
                className: `text-lg md:text-2xl font-medium text-white max-w-100 truncate`
              }}
            />
          </div>
          <div className='flex flex-row-reverse gap-2 self-center'>
            <TextWithIcon
              {...{
                className:
                  'bg-white text-brand text-xs md:text-sm font-medium rounded px-2 py-1 self-baseline',
                icon: 'order-brand-icon.svg',
                label: parseNumberedLabel(orderNumber),
                labelStyle: 'text-brand text-xs md:text-sm font-medium '
              }}
            />

            {status === ORDER_STATUS.CANCELLED.value && (
              <IconWithLabel
                {...{
                  containerClass:
                    'flex gap-1 md:gap-2 bg-white rounded-2xl px-2 items-center',
                  imgAlt: 'cancelled order',
                  imgHeight: 16,
                  imgSrc: 'cancelled-order-icon.svg',
                  imgWidth: 16,
                  value: 'Event Cancelled',
                  valueStyle: 'text-brand text-sm font-medium flex self-center'
                }}
              />
            )}
          </div>
        </div>
        <IconWithLabel
          {...{
            containerClass: 'flex gap-2',
            imgAlt: 'location',
            imageContainerClass: 'w-6 self-center',
            imgHeight: 24,
            imgSrc: 'location-white.svg',
            imgWidth: 24,
            value: exactAddress,
            valueStyle: 'text-sm md:text-base text-white font-medium'
          }}
        />
        <div className='flex gap-4 mt-2'>
          <div className='flex gap-3 w-1/4'>
            <div className='w-6 md:w-8 self-center'>
              <Image
                alt='calendar'
                height={0}
                src={`${staticMediaStoreBaseURL}/icons/calendar.svg`}
                style={{ width: 24, height: 24 }}
                width={0}
              />
            </div>
            <TextKeyValuePair
              {...{
                className: 'flex-col gap-1',
                label: 'Event Date',
                labelClass:
                  'text-xs md:text-sm font-medium text-white uppercase',
                spaceTop: '',
                value: convertToStandardDateFormat(eventDate),
                valueClassName:
                  'text-sm md:text-base text-white font-medium max-w-48 truncate'
              }}
            />
          </div>

          <div className='flex gap-3 w-1/3'>
            <div className='w-6 md:w-8 self-center'>
              <Image
                alt='user'
                height={0}
                src={`${staticMediaStoreBaseURL}/icons/planner-icon.svg`}
                style={{ width: 28, height: 28 }}
                width={0}
              />
            </div>
            <TextKeyValuePair
              {...{
                className: 'flex-col self-center gap-1 flex-1',
                label: 'Event Planner',
                labelClass:
                  'text-xs md:text-sm font-medium text-white uppercase',
                spaceTop: '',
                value: plannerName,
                valueClassName:
                  'text-sm md:text-base text-white font-medium max-w-48 truncate'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderEventInfoWeb;
